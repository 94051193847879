<template>
  <div class="error">
    <slot />
  </div>
</template>

<style lang="scss" scoped>
$color-red: #f33;

.error {
  position: absolute;
  bottom: calc(-1.5em);
  color: $color-red;
  font-size: 1.2rem;
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  margin-top: 5px;
  letter-spacing: 0.5px;
}
</style>
